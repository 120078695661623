import * as React from 'react';
import {Navigate} from "react-router";
import {AdminType} from "../../../__generated__/globalTypes";
import {LoadingFragment} from "../../../pages/LoadingPage";
import ErrorPage from "../../../pages/ErrorPage/ErrorPage";
import {BackgroundColor, Card, classNames, Padding} from "@snoam/pinata";
import RoleBoundary, {RoleBoundaryState} from "../../../components/Boundary/RoleBoundary";
import {MinBedriftRouteComponentProps} from "../../utils";

const AdminBoundary: React.FunctionComponent<{ role: AdminType | AdminType[] }> = ({children, role, ...otherProps}) => (
  <RoleBoundary
    {...otherProps as MinBedriftRouteComponentProps}
    role={role}
  >
    {
      ({roleBoundaryState, error}) => {
        switch (roleBoundaryState) {
          case RoleBoundaryState.HAS_ACCESS:
            return children;
          case RoleBoundaryState.LOADING:
            return <LoadingFragment/>;
          case RoleBoundaryState.NOT_LOGGED_IN:
            return <Navigate to={'/login'}/>;
          case RoleBoundaryState.ERROR:
            return <ErrorPage heading={'Det oppstod en feil'} message={error!.message}/>;
          case RoleBoundaryState.NO_ACCESS:
            // eslint-disable-next-line no-case-declarations
            const message = (
              <Card className={classNames(BackgroundColor.BG_WHITE, Padding.PX_6, Padding.PY_8)}>
                Du har ikke tilstrekkelig tilgang til å kunne vise denne siden
              </Card>
            );
            return (
              <ErrorPage
                heading={'Beklager!'}
                message={message}/>
            );
          default:
            return null;
        }
      }
    }
  </RoleBoundary>
);


export default AdminBoundary;
